<template>
  <div class="atom-input atom-input-textarea__wrapper">
    <div
      class="atom-input-textarea"
      :class="{
        'has-input': hasInput,
        'is-disabled': isDisabled,
        'has-error': showErrors,
      }"
    >
      <label class="atom-input-textarea__label" :for="name">
        {{ label + (isRequiredVal ? ' *' : '') }}
      </label>

      <textarea
        :id="name"
        v-model="inputValue"
        :name="name"
        :placeholder="placeholder"
        :required="isRequiredVal"
        class="atom-input-textarea__input"
        @focus="setFocus(true)"
        @focusout="setFocus(false)"
        @mouseenter="setHover(true)"
        @mouseleave="setHover(false)"
      />
    </div>
  </div>
</template>

<script setup>
/**
 * This component provides a textarea input field with label and validation support.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.initialValue=''] - The initial value of the input field.
 * @param {array} [props.validations=[]] - An array of validation rules.
 * @param {boolean} [props.isDisabled=false] - Whether the input field is disabled.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: © 2023 Nueva AG
 */

const props = defineProps({
    label: {
        type: String,
        default: '',
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: String,
        default: '',
        validator: (value) => typeof value === 'string',
    },

    placeholder: {
        type: String,
        default: '',
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
    isRequiredVal: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

/*
    Intialze formfield
*/
const errors = ref([]);
const inputValue = ref('');

const setInitialValue = () => {
    inputValue.value = props.initialValue;
};

setInitialValue();

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error']);

/* Watch input */
const handleData = (value) => {
    if (props.isDisabled) return;

    const { validationErrors } = useFormfieldEmit(
        value,
        props.name,
        emit,
        props.validations,
    );

    errors.value = validationErrors;
};
watch(() => inputValue.value, (newValue) => {
    handleData(newValue);
});

handleData(inputValue.value);

/*
    States
*/
const {
    setHover,
    setFocus,
    hasInput,
    showErrors,
} = useFormFieldStates(inputValue, errors, props);

const colorTopLevel = inject('colorSchema');
const colorTextfield = computed(() => `var(--c-${colorTopLevel.value}-4)`);
const colorTitle = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const colorPlaceholder = computed(() => `var(--c-${colorTopLevel.value}-9)`);
const colorBackground = computed(() => `var(--c-${colorTopLevel.value}-13)`);
</script>

<style lang="scss" scoped>
.atom-input-textarea__wrapper {
    @include formFieldWrapper;
}
.atom-input-textarea {
    @include formFieldBasicsText;
}

.atom-input-textarea__label {
    @include formFieldLabelText;
    color: v-bind(colorTitle);
}

.atom-input-textarea__input {
    @include formFieldInputTextArea;

    min-height: 216px;
    background-color: v-bind(colorBackground);
    color: v-bind(colorTextfield);

    &::placeholder {
        color: v-bind(colorPlaceholder);
    }
}
</style>
