<template>
  <div
    v-for="option in options"
    :key="option.name"
    class="atom-input atom-input-radio__wrapper"
    :class="{
      'is-disabled': isDisabled || option.isDisabled,
      'has-error': showErrors,
    }"
    @mouseenter="setHover(true); setFocus(true);"
    @mouseleave="setHover(false); setFocus(false);"
    @focusin="setHover(true)"
    @focus="setFocus(true)"
    @focusout="setFocus(false)"
  >
    <input
      :id="option.name"
      v-model="inputValue"
      :name="name"
      :value="option.value"
      type="radio"
      class="atom-input-radio__input"
      :required="isRequiredVal"
    />

    <label
      class="atom-input-radio__label"
      :for="option.name"
    >
      {{ option.name }}
    </label>
  </div>
</template>

<script setup>
/**
 *
 * This component is a radio input field that can be used to select between two options.
 * It displays a button with a label and an optional asterisk to indicate that the field
 * is required.
 *
 * When the button is clicked, it radios between two states and
 * emits an event with the new value.
 * The component also supports validation by displaying error messages when the input is invalid.
 *
 * @prop:
 * - label: The label to display on the button
 * - name: The name of the input field
 * - initialValue: The initial value of the input field
 * - validations: An array of validation functions to run on the input value
 * - isDisabled: Whether the input field is disabled or not
 *
 * @data:
 * - errors: An array of error messages
 * - inputValue: The current value of the input field
 *
 * @method:
 * - setInitialValue: Sets the initial value of the input field
 * - handleData: Handles changes to the input value and emits events
 *
 * @emits:
 * - set-input: Emits the new value of the input field
 * - set-error: Emits an error message when the input is invalid
 *
 * @author: Luca Margadant (luca.margadant@nueva.ch)
 * @copyright: Nueva AG
 */

const props = defineProps({
    label: {
        type: String,
        default: '',
    },

    name: {
        type: String,
        default: '',
        required: false,
    },

    initialValue: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    isRequiredVal: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

/*
    Intialze formfield
*/
const errors = ref([]);
const inputValue = ref('');

const setInitialValue = () => {
    inputValue.value = props.initialValue;
};

setInitialValue();

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error']);

/* Watch input */
const handleData = (value) => {
    if (props.isDisabled) return;

    const { validationErrors } = useFormfieldEmit(
        value,
        props.name,
        emit,
        props.validations,
    );

    errors.value = validationErrors;
};
watch(() => inputValue.value, (newValue) => {
    handleData(newValue);
});

handleData(inputValue.value);

/*
    States
*/
const {
    setHover,
    setFocus,
    showErrors,
} = useFormFieldStates(inputValue, errors, props);

const colorTopLevel = inject('colorSchema');
const color = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss" scoped>
.atom-input-radio__wrapper {
    @include formFieldWrapper;

    width: unset;
    align-items: center;
    cursor: pointer;

    &.is-disabled {
        opacity: 0.2;
        pointer-events: none;
    }
}

.atom-input-radio__input {
    position: relative;
    width: 18px;
    height: 24px;
    flex: 0 0 auto;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;

    &:before {
        display: block;
        width: 16px;
        height: 16px;
        border: 2px solid v-bind(color);
        border-radius: 100%;
        background-color: transparent;
        content: '';
    }

    &:after {
        position: absolute;
        top: 5px;
        left: 5px;
        display: block;
        width: 8px;
        height: 8px;
        box-sizing: content-box;
        border: none;
        border-radius: 100%;
        border-right: 2px solid v-bind(color);
        border-bottom: 2px solid v-bind(color);
        background-color: v-bind(color);
        content: '';
        transform: scale(0);
    }

    &:checked:after {
        animation: toggleOnradio 0.3s ease forwards;
    }
}

.atom-input-radio__label {
    margin-left: 10px;
    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

@keyframes toggleOnradio {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    70% {
        opacity: 1;
    }

    100% {
        transform: scale(0.6);
    }
}
</style>
