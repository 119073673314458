<template>
  <div class="storyblok-input-multiselect">
    <AtomInputMultiselect
      :options="options"
      :label="label"
      :name="name"
      :title="title"
      :default-value="defaultValue"
      :is-required="isRequired"
      @set-input="handleData"
    />
  </div>
</template>

<script setup>
defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: true,
    },

    name: {
        type: String,
        required: true,
    },

    title: {
        type: String,
        default: null,
    },

    initialValue: {
        type: String,
        default: '',
    },

    isRequired: {
        type: Boolean,
        default: false,
    },

    options: {
        type: Array,
        default: () => [],
    },

    defaultValue: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['set-input', 'set-error']);

const handleData = (data) => {
    emit('set-input', data);
};
</script>
