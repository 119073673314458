<template>
  <component
    :is="useComponent(item.component)"
    v-for="item of data"
    v-bind="buildProps(item)"
    :key="item._uid"
    ref="fieldRefs"
    v-editable="item"
    :color="color"
    @set-input="sendData"
  />
</template>

<script setup>
const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    prefillEmail: {
        type: String,
        default: '',
    },
    color: {
        type: String,
        default: '',
    },
    groupKey: {
        type: String,
        default: null,
    },
});

const emit = defineEmits(['set-input', 'set-error']);

const fieldRefs = ref([]);
const groupedData = {};

const sendData = (data) => {
    if (props.groupKey) {
        groupedData[data.name] = data;

        /* Filter out all undefined or empty values */
        Object.keys(groupedData).forEach((key) => {
            if (groupedData[key].value === undefined || groupedData[key].value === null) {
                delete groupedData[key];
            }
        });

        emit('set-input', {
            name: props.groupKey,
            value: groupedData,
            isGrouped: true,
        });
        return;
    }

    if (data) {
        emit('set-input', data);
    }
};

const buildProps = (data) => {
    const itemProps = { ...data };
    delete itemProps.component;
    delete itemProps._uid;

    const mergedProps = {
        ...itemProps,
        isRequired: data.is_required || data.isRequired,
        is_required: data.is_required || data.isRequired,
    };

    return mergedProps;
};

/*
  Add more components here if you need them in the storybook render loop
*/
const availableComponents = {
    StoryblokInputCheckbox: resolveComponent('StoryblokInputCheckbox'),
    StoryblokInputMultiselect: resolveComponent('StoryblokInputMultiselect'),
    StoryblokInputRadio: resolveComponent('StoryblokInputRadio'),
    StoryblokInputText: resolveComponent('StoryblokInputText'),
    StoryblokInputTextarea: resolveComponent('StoryblokInputTextarea'),
    StoryblokInputSingleselect: resolveComponent('StoryblokInputSingleselect'),

    AtomInputText: resolveComponent('AtomInputText'),
    AtomInputTextarea: resolveComponent('AtomInputTextarea'),
    AtomInputCheckbox: resolveComponent('AtomInputCheckbox'),
    AtomInputRadio: resolveComponent('AtomInputRadio'),
    AtomInputSingleselect: resolveComponent('AtomInputSingleselect'),
    AtomInputMultiselect: resolveComponent('AtomInputMultiselect'),
};

const useComponent = (key) => availableComponents[key] || null;

defineExpose({
    fieldRefs,
});
</script>
