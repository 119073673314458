<template>
  <div
    class="atom-input atom-input__wrapper"
    :class="{ 'has-options-open': showOptions }"
  >
    <div
      v-if="title"
      class="atom-input__title"
    >
      {{ title }}
    </div>

    <div
      ref="wrapperRef"
      class="atom-singleselect__wrapper"
      @mouseenter="showOptionsOnHover(true)"
      @mouseleave="showOptionsOnHover(false)"
      @focusin="showOptionsOnHover(true)"
      @focusout="showOptionsOnHover(false)"
      @click="toggleOptions(false)"
      @keypress="toggleOptions(false)"
    >
      <div
        class="atom-singleselect"
      >
        <p class="atom-singleselect__label">
          {{ label + (isRequired ? ' *' : '') }}
        </p>

        <select
          ref="inputRef"
          v-model="selectedOption"
          class="atom-singleselect__input"
          :name="name"
          :disabled="isDisabled"
          :required="isRequired ? 'required' : null"
          aria-hidden="true"
          tabindex="-1"
          @change="handleData"
          @mousedown.prevent
        >
          <option
            v-for="option in options"
            :key="'real-select-option-' + option.name"
            :value="option.value"
            :selected="selectedOption === option.value"
          >
            {{ option.name }}
          </option>
        </select>

        <IonIcon
          class="atom-singleselect__icon has-pointer is-right"
          icon-name="chevron-down"
        />
      </div>

      <div
        class="atom-singleselect__options-wrapper"
        :class="{ 'is-visible': showOptions }"
      >
        <div
          ref="optionsRef"
          class="atom-singleselect__options"
        >
          <div
            v-for="option in options"
            :key="'option-' + option.name"
            class="atom-singleselect__option"
            :class="{ 'is-selected': selectedOption === option.value }"
          >
            <button
              type="button"
              class="atom-singleselect__option-button"
              @click="singleselectOption(option)"
            >
              <span class="atom-singleselect__option-background" />
              <span class="atom-singleselect__option-label">{{ option.name }}</span>
              <IonIcon
                class="atom-singleselect__icon-close has-pointer is-right"
                icon-name="close"
              />
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="!showOptions"
        class="atom-singleselect__selected"
      >
        <div
          v-for="option in options"
          :key="'selected-option-' + option.name"
          class="atom-singleselect__selected-option"
          :class="{ 'is-selected': selectedOption === option.value }"
        >
          <template v-if="!option.choose_other">
            <button
              type="button"
              class="atom-singleselect__selected-option-button"
              @click="singleselectOption(option)"
            >
              <span class="atom-singleselect__selected-option-background" />
              <span class="atom-singleselect__selected-option-label">{{ option.name }}</span>
              <IonIcon
                class="atom-singleselect__icon-close has-pointer is-right"
                icon-name="close"
              />
            </button>
          </template>

          <AtomInputText
            v-else
            class="atom-singleselect__other-input"
            :label="option.name"
            :name="option.value"
            @input="setOtherInput"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useWindowSize, onClickOutside } from '@vueuse/core';

const props = defineProps({
    /*
          General data
      */
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    type: {
        type: String,
        validator: (value) => value.length > 0,
        default: 'singleselect',
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    title: {
        type: String,
        default: null,
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    isRequired: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

/*
      Intialze formfield
  */
const wrapperRef = ref(null);

/*
      Handle input
  */
// const emit = defineEmits(['set-data']);
const emit = defineEmits(['set-input', 'set-error']);

/*
      Custom Logic
  */
const optionsRef = ref(null);
const closedHeight = '4px';
const optionsHeight = ref(closedHeight);

/* handle singleselected options */
const selectedOption = ref(null);

const handleData = () => {
    useFormfieldEmit(
        selectedOption.value,
        props.name,
        emit,
        props.validations,
    );
};

/* show and hide options */
const showOptions = ref(false);
const toggleOptions = (forceClose = false) => {
    showOptions.value = forceClose ? false : !showOptions.value;

    optionsHeight.value = showOptions.value
        ? `${optionsRef.value.scrollHeight}px`
        : closedHeight;
};

const showOptionsOnHover = (value) => {
    showOptions.value = value;
};

const singleselectOption = (option) => {
    selectedOption.value = option.value;
    toggleOptions();
    handleData();
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    if (showOptions.value) {
        toggleOptions();
    }
});

/* Watch window width to set height of box accordingly on resize */
const { width } = useWindowSize();
watch(() => width.value, () => {
    if (!props.isFullScreen) {
        optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
    }
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
const color6 = computed(() => `var(--c-${colorTopLevel.value}-6)`);
const color11 = computed(() => `var(--c-${colorTopLevel.value}-13)`);

const setOtherInput = (e) => {
    const { value } = e.target;
    selectedOption.value = value;
    const otherOption = props.options.find((option) => option.choose_other === true);

    if (otherOption) {
        otherOption.value = value;
    }

    handleData();
};

const resetOptions = () => {
    selectedOption.value = null;
};

const runReset = inject('runReset');

watch(runReset, () => {
    resetOptions();
});

defineExpose({
    isRequired: props.isRequired,
    selectedOption,
    resetOptions,
});
</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;
    display: flex;
    flex-direction: column;

    user-select: none;
}

.atom-singleselect__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    cursor: pointer;
}

.atom-singleselect {
    @include formFieldBasicsText;

    position: relative;
    display: flex;
    max-width: 100%;
    height: 30px;
    align-items: flex-end;
    background: v-bind(color11);

    @include mobile {
        width: 100%;
    }
}

.atom-singleselect__label {
    @include formFieldLabelText;

    display: flex;
    height: 30px;
    align-items: center;
    padding-top: 2px;
    padding-right: 38px;
    margin-bottom: 0;
    color: v-bind(color6);
    cursor: pointer;
    font-size: 14px;
    line-height: normal;
    pointer-events: none;
}

.atom-singleselect__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
}

.atom-singleselect__icon {
    @include formFieldInputIcon(false);

    top: 5px;
    right: 6px !important;
    width: 22px;
    height: 22px;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    ::v-deep(path) {
        stroke: v-bind(color4);
    }

    .has-options-open & {
        transform: rotate(180deg);
    }
}
.atom-singleselect__trigger {
    @include z-index('selectTrigger');

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
}

.atom-singleselect__options-wrapper {
    @include z-index('selectTrigger');

    position: absolute;
    top: 30px;
    width: 100%;

    &.is-visible {
        display: block;
    }
}

.atom-singleselect__options {
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: height 0.5s $EASE_OUT--QUINT;

    .has-options-open & {
        height: 100%;
    }
}

.atom-singleselect__option {

    position: relative;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-singleselect__selected-option {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-top: 3px;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-singleselect__option-divider {
    display: block;
    width: calc(100% - 40px);
    height: 2px;
    margin: 0 auto;
}

.atom-singleselect__option-button {
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: flex-end;
}

.atom-singleselect__selected-option-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.atom-singleselect__option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(color11);
    transition: background-color 0.2s ease-in-out;
}

.atom-singleselect__selected-option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(color11);
    transition: background-color 0.2s ease-in-out;
}

.atom-singleselect__option-label {
    @include z-index('selectLabel');

    position: relative;
    margin: 6px 36px 6px 10px;
    color: v-bind(color6);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
    text-align: right;

    .is-selected & {
        color: v-bind(color1);
    }
}

.atom-singleselect__selected-option-label {
    @include z-index('selectLabel');
    position: relative;
    padding: 6px 36px 6px 10px;
    color: v-bind(color6);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);

    .is-selected & {
        color: v-bind(color1);
    }
}

.atom-singleselect__icon-close {
    @include formFieldInputIcon(false);
    @include z-index('selectLabel');

    top: 8px;
    right: 10px !important;
    width: 14px;
    height: 14px;
    opacity: 0;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    ::v-deep(path) {
        stroke: v-bind(color4);
    }

    .is-selected & {
        right: 10px;
        opacity: 1;
    }

}

.atom-singleselect__selected {
    .atom-singleselect__selected-option {
        display: none;

        &.is-selected {
            display: flex;
        }
    }
}

.atom-singleselect__other-input {
    margin-top: 15px;
}

.atom-input__title {
    margin-bottom: 10px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}
</style>
