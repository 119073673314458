<template>
  <form
    v-if="isArrayNotEmpty(fieldsets)"
    ref="formRef"
    class="block-form-controller"
    method="post"
    @set-input="sendData"
    @submit.prevent="onSubmit"
  >
    <div class="block-form-controller__inner">
      <fieldset
        v-for="fieldgroup in fieldsets"
        :key="fieldgroup._uid"
      >
        <div class="block-form-controller__fieldgroup-title">
          {{ fieldgroup.title }}
        </div>
        <div
          class="block-form-controller__fieldgroup"
          :class="{ 'is-two-column': fieldgroup.is_two_column }"
        >
          <UtilRendererForm
            ref="groupRefs"
            :data="fieldgroup.fields"
            :prefill-email="prefillEmail"
            :group-key="fieldgroup.groupKey"
            @set-input="sendData"
          />
        </div>
      </fieldset>
    </div>

    <AtomButton
      class="block-form-controller__submit"
      :text="buttonText"
      type="submit"
      appereance="primary"
    />
    <label
      :style="{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        width: 0,
        height: 0,
        opacity: 0,
      }"
      for="userWebsite"
    >
      <span>Name</span>
      <input
        id="userWebsite"
        ref="userWebsite"
        name="user_website"
        autocomplete="off"
        tabindex="-1"
        value=""
        type="text"
        @input="inputUserWebsite = $event.target.value"
      />
    </label>
  </form>
</template>

<script setup>
defineProps({
    fieldsets: {
        type: Array,
        default: () => [],
    },

    buttonText: {
        type: String,
        default: '',
    },

    prefillEmail: {
        type: String,
        default: '',
    },
});

const { locale: currentLocale } = useI18n();

const submitData = ref({
    language: currentLocale.value,
});

const emit = defineEmits(['on-submit']);

const sendData = (data) => {
    // add value to key in submitData
    if (data) {
        submitData.value[data.name] = data.value;
    }
};

/*
  Recaptcha
*/
const { executeRecaptcha } = useGoogleRecaptcha();

/*
    Honeypot
*/
const userWebsite = ref('userWebsite');
const inputUserWebsite = ref(null);

const formRef = ref(null);
const groupRefs = ref(null);
const runReset = ref(0);
provide('runReset', runReset);

const onSubmit = async () => {
    /* Check HoneyPot */
    if (inputUserWebsite.value) {
        return;
    }

    const { token } = await executeRecaptcha('submit');

    const bodyData = {
        ...submitData.value,
        recaptchaToken: token,
    };

    emit('on-submit', bodyData);
    formRef.value.reset();

    runReset.value += 1;
};

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => `var(--c-${colorTopLevel?.value}-1)`);
</script>

<style lang="scss" scoped>
.block-form-controller {
    display: flex;
    flex-direction: column;
    color: v-bind('colorText');
}

.block-form-controller__submit {
    align-self: flex-end;

    .block-form-login__form & {
        align-self: flex-start;
    }
}

.block-form-controller__inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    color: v-bind('colorText');
    row-gap: 20px;

    @include mobile {
        row-gap: 30px;
    }
}

.block-form-controller__fieldgroup {
    @include grid-layout(1);

    row-gap: 20px;

    &.is-two-column {
        @include grid-layout(2);
        column-gap: 36px;

        @include mobile {
            @include grid-layout(1);
        }
    }
}

.block-form-controller__fieldgroup-title {
    margin-top: 40px;
    margin-bottom: 25px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}
</style>
