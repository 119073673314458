<template>
  <div
    class="storyblok-input-radio"
  >
    <div v-if="label" class="storyblok-input-radio__label">
      {{ label + (isRequired ? ' *' : '') }}
    </div>

    <AtomInputRadio
      :label="label"
      :name="name"
      :options="options"
      :is-required-val="isRequired"
      @set-input="handleData"
    />
  </div>
</template>

<script setup>
defineProps({
    label: {
        type: String,
        default: '',
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
    isRequired: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

const emit = defineEmits(['set-input', 'set-error']);

const handleData = (data) => {
    emit('set-input', data);
};

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.storyblok-input-radio {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 20px;
}

.storyblok-input-radio__label {
    @include formFieldLabelText;
    min-width: 100%;
    color: v-bind(colorText);
}
</style>
