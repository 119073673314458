<template>
  <div class="storyblok-input-checkbox">
    <AtomInputCheckbox
      :label="label"
      :name="name"
      :is-required-val="isRequired"
      @set-input="handleData"
    />
  </div>
</template>

<script setup>
defineProps({
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
    },

    initialValue: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
    isRequired: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

const emit = defineEmits(['set-input', 'set-error']);

const handleData = (data) => {
    const newData = data;
    newData.isCheckbox = true;
    emit('set-input', newData);
};
</script>
