<template>
  <div
    class="atom-input atom-input-checkbox__wrapper"
    :class="{
      'is-disabled': isDisabled,
    }"
    @mouseenter="setHover(true); setFocus(true);"
    @mouseleave="setHover(false); setFocus(false);"
    @focusin="setHover(true)"
    @focus="setFocus(true)"
    @focusout="setFocus(false)"
  >
    <input
      :id="name"
      v-model="inputValue"
      type="checkbox"
      class="atom-input-checkbox__input"
      :required="isRequiredVal"
    />

    <label
      class="atom-input-checkbox__label"
      :for="name"
    >
      {{ label + (isRequiredVal ? ' *' : '') }}
    </label>
  </div>
</template>

<script setup>
/**
 *
 * This component is a checkbox input field that can be used to select between two options.
 * It displays a button with a label and an optional asterisk to indicate that the field
 * is required.
 *
 * When the button is clicked, it checkboxs between two states and
 * emits an event with the new value.
 * The component also supports validation by displaying error messages when the input is invalid.
 *
 * @prop:
 * - label: The label to display on the button
 * - name: The name of the input field
 * - initialValue: The initial value of the input field
 * - validations: An array of validation functions to run on the input value
 * - isDisabled: Whether the input field is disabled or not
 *
 * @data:
 * - errors: An array of error messages
 * - inputValue: The current value of the input field
 *
 * @method:
 * - setInitialValue: Sets the initial value of the input field
 * - handleData: Handles changes to the input value and emits events
 *
 * @emits:
 * - set-input: Emits the new value of the input field
 * - set-error: Emits an error message when the input is invalid
 *
 * @author: Luca Margadant (luca.margadant@nueva.ch)
 * @copyright: Nueva AG
 */

const props = defineProps({
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
    },

    initialValue: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
    isRequiredVal: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

});

/*
    Intialze formfield
*/
const errors = ref([]);
const inputValue = ref('');

const setInitialValue = () => {
    inputValue.value = props.initialValue;
};

setInitialValue();

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error']);

/* Watch input */
const handleData = (value) => {
    if (props.isDisabled) return;

    const { validationErrors } = useFormfieldEmit(
        value,
        props.name,
        emit,
        props.validations,
    );

    errors.value = validationErrors;
};
watch(() => inputValue.value, (newValue) => {
    handleData(newValue);
});

handleData(inputValue.value);

/*
    States
*/
const {
    setHover,
    setFocus,
} = useFormFieldStates(inputValue, errors, props);

const colorTopLevel = inject('colorSchema');
const color = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss" scoped>
.atom-input-checkbox__wrapper {
    @include formFieldWrapper;

    cursor: pointer;

    &.is-disabled {
        opacity: 0.2;
        pointer-events: none;
    }
}

.atom-input-checkbox__input {
    position: relative;
    width: 22px;
    height: 25px;
    flex-basis: 22px;
    flex-shrink: 0;
    border: none;
    border-radius: 0;
    margin-top: 2px;
    appearance: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;

    @include mobile {
        margin-top: 0;
    }

    &:before {
        display: block;
        width: 20px;
        height: 20px;
        border: 2px solid v-bind(color);
        border-radius: 0;
        background-color: transparent;
        content: '';
    }

    &:after {
        position: absolute;
        top: -2px;
        left: 5px;
        display: block;
        width: 12px;
        height: 20px;
        box-sizing: content-box;
        border-right: 3px solid v-bind(color);
        border-bottom: 3px solid v-bind(color);
        content: '';
        transform: rotate(45deg) scale(0);
    }

    &:checked:after {
        animation: toggleOnCheckbox 0.3s ease forwards;
    }
}

.atom-input-checkbox__label {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

@keyframes toggleOnCheckbox {
    0% {
        opacity: 0;
        transform: scale(0) rotate(45deg);
    }

    70% {
        opacity: 1;
    }

    100% {
        transform: scale(0.6) rotate(45deg);
    }
}
</style>
